<template>
<div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="applyExport()">导出</Button>
    </Col>
  </Row>
  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
        <template slot="gender" slot-scope="{row}">
          <span v-if="row.user_gender === 1">男</span>
          <span v-else>女</span>
        </template>
        <template slot="q1" slot-scope="{row}">
          <p v-html="row.activity_user_answer1"></p>
        </template>
        <template slot="q2" slot-scope="{row}">
          <p v-html="row.activity_user_answer2"></p>
        </template>
        <template slot="q3" slot-scope="{row}">
          <p v-html="row.activity_user_answer3"></p>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu'
export default {
  name: "ActivityApply",
  data: function(){
    return {
      searchKey: {
        page: 1,
        pagesize: 30
      },
      activity: {},
      list: [],
      total: 0,
      activity_id: '',
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '姓名', key: 'user_real_name',width: 120},
        {title: '电话', key: 'user_phone', width: '130'},
        {title: '性别', slot: 'gender', width: 80},
        {title: '出生日期', key: 'user_birthday', width: 120},
        {title: '工作单位', key: 'user_work', width: 200},
        {title: '报名时间', key: 'activity_user_jointime', width: 170}
      ],
    }
  },
  methods: {
    applyExport: function(){
      this.$http.get(api.activityApplyExport, {params: {activity_id: this.activity_id}}).then(response => {
        if(response.data.success) {
          window.location.href = response.data.path;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.applyList, {params: {id:this.activity_id}}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/activity')
    this.activity_id = this.$route.query.id
    this.getList()
    this.$http.get(api.activityDetail, {params: {
      activity_id: this.activity_id
    }}).then(response => {
      if(response.data.success) {
        this.activity = response.data.res;
        if(this.activity.activity_question1) {
          this.columns.push({
            title: this.activity.activity_question1,
            slot: 'q1'
          })
        }
        if(this.activity.activity_question2) {
          this.columns.push({
            title: this.activity.activity_question2,
            slot: 'q2'
          })
        }
        if(this.activity.activity_question1) {
          this.columns.push({
            title: this.activity.activity_question3,
            slot: 'q3'
          })
        }
        // const _this = this
        // this.list.forEach((item, index) => {
        //   _this.list[index].answer1_type = _this.activity.activity_question1_type;
        //   _this.list[index].answer2_type = _this.activity.activity_question2_type;
        //   _this.list[index].answer3_type = _this.activity.activity_question3_type
        // })
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>