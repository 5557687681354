var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Row',{attrs:{"gutter":10}},[_c('Col',{staticStyle:{"text-align":"right"},attrs:{"span":"24"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.applyExport()}}},[_vm._v("导出")])],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{attrs:{"span":"24"}},[_c('Table',{attrs:{"stripe":"","border":"","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var index = ref.index;
return [_vm._v(_vm._s(index + 1))]}},{key:"gender",fn:function(ref){
var row = ref.row;
return [(row.user_gender === 1)?_c('span',[_vm._v("男")]):_c('span',[_vm._v("女")])]}},{key:"q1",fn:function(ref){
var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(row.activity_user_answer1)}})]}},{key:"q2",fn:function(ref){
var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(row.activity_user_answer2)}})]}},{key:"q3",fn:function(ref){
var row = ref.row;
return [_c('p',{domProps:{"innerHTML":_vm._s(row.activity_user_answer3)}})]}}])})],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('Page',{attrs:{"show-sizer":"","show-elevator":"","show-total":"","total":_vm.total,"current":_vm.searchKey.page,"page-size":_vm.searchKey.pagesize},on:{"on-change":_vm.pageChange,"on-page-size-change":_vm.pageSizeChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }